import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import config from '../../../config.json';
import {
  BackgroundTexture,
  DriftLogo,
  DriftMenu,
  HeadingWithIcon,
  Layout,
  SEO,
  SideBySide,
} from '../../components';
import { ClockIcon } from '../../components/vectors/clock';
import { useGraphQL } from '../../hooks';

function DriftPage(): React.ReactElement {
  return (
    <Layout>
      <SEO title="Drift" />
      <Background />
      <DriftBarCafe />
      <DriftMenu />
      <OpeningHours />
    </Layout>
  );
}

function Background() {
  return (
    <>
      <div aria-hidden className="fixed inset-0">
        <BackgroundTexture insetBottom="full" overflowBottom />
      </div>
      <div aria-hidden className="absolute inset-x-0 h-24 bg-white" />
    </>
  );
}

function DriftBarCafe(): React.ReactElement {
  const { file } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "drift-bar-hero.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);
  return (
    <div className="relative py-12">
      <div className="relative">
        <SideBySide
          sideComponent={
            <GatsbyImage
              image={file.childImageSharp.gatsbyImageData}
              className="flex-1 h-full"
              alt=""
            />
          }
        >
          <div className="w-full mx-auto max-w-prose">
            <HeadingWithIcon>
              <HeadingWithIcon.Heading>
                Drift
                <br />
                Bar·Cafe· <br />
                Restaurant
              </HeadingWithIcon.Heading>
              <HeadingWithIcon.Icon hidden>
                <DriftLogo />
              </HeadingWithIcon.Icon>
            </HeadingWithIcon>
            <div className="mt-2 prose">
              <p>
                Bringing you simple food done well, where every ingredient on
                the plate can be a hero and will make your taste buds sing!
              </p>
              <p>
                Drift specials are not guaranteed and while stocks last. Drift
                specials also are subject to change without prior notice.
              </p>
            </div>
            <div className="grid mt-5 gap-y-5 gap-x-4 md:grid-cols-2 lg:grid-cols-1">
              <a
                href={`tel:${config.phone}`}
                className="text-center bg-white border whitespace-nowrap button text-blue-dark border-blue-dark hover:text-white"
              >
                Call to book - {config.phone}
              </a>
            </div>
          </div>
        </SideBySide>
      </div>
    </div>
  );
}

function OpeningHours(): React.ReactElement {
  const { driftBarOpeningHours } = useGraphQL();
  return (
    <div className="relative py-12 bg-white">
      <SideBySide
        isReversed={false}
        sideComponent={
          <GatsbyImage
            image={driftBarOpeningHours.childImageSharp.gatsbyImageData}
            className="flex-1 h-full"
            alt=""
          />
        }
      >
        <div className="flex h-full bg-blue-light">
          <div className="flex flex-col justify-center w-full p-4 pt-8 mx-auto max-w-prose">
            <HeadingWithIcon>
              <HeadingWithIcon.Heading>
                Opening
                <br />
                hours
              </HeadingWithIcon.Heading>
              <HeadingWithIcon.Icon hidden>
                <ClockIcon />
              </HeadingWithIcon.Icon>
            </HeadingWithIcon>
            <div className="mt-5 prose">
              <p>Open 7 days</p>
              <dl className="mt-4 space-y-4">
                <div>
                  <dt className="font-semibold uppercase">Lunch</dt>
                  <dd>11:30am to 2:00pm</dd>
                </div>
                <div>
                  <dt className="font-semibold uppercase">Dinner</dt>
                  <dd>from 5:30pm</dd>
                </div>
                <div>
                  <dt className="font-semibold uppercase">
                    Booking recommended
                  </dt>
                  <dd>
                    Call:{' '}
                    <a
                      href={`tel:${config.phone}`}
                      className="transition duration-150 ease-in-out hover:text-white hover:underline"
                    >
                      {config.phone}
                    </a>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </SideBySide>
    </div>
  );
}

export { DriftPage as default };
